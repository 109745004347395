.scroll-wrapper {
  position: absolute;
  bottom: -7vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
  cursor: pointer;
}

.scroll-text {
  color: #000000;
  text-align: center;
}

.scroll-arrow {
  height: 4vh;
  width: auto;
}

.fade-in {
  opacity: 0;
}
